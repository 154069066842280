.App {
  
  margin-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
  /*text-align: center;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pre
{
  border: 1px solid #999;
  page-break-inside: avoid;
  display:  inline-block;
  padding: 3px 3px 2px;
  margin: 0 0 10px;
  /*font-size: 13px;*/
  /*line-height: 20px;*/
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #fafafa;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-family: Consolas, "Courier New", monospace;
  /*font-size: 12px;*/
  color: #666666;
}

